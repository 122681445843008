<template>
<div class="w-100">
  <!-- start heading -->
  <div class="page-content">
  <div class="row d-block d-lg-none">
    <div class="col-sm-12">
    <p class="text-center page-heading">Message</p>
    </div>
   </div>
   </div>
   <!-- block -->
   <div class="">
    <div class="d-block">
        <div class="row">
          <div class="col-12">
              <h4><router-link to="/doctor/message" class="text-dark"><font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/></router-link><span class="text-color"> New message</span></h4>
          </div>
          <hr class="hr-border"/>
        </div>
     </div> 
      <ChatBox title="newchat"></ChatBox>


 </div>
</div>
</template>
<script>
import ChatBox from './chatbox.vue'
export default {
   components:{
      ChatBox,
    },
}
</script>